import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Why Spacers Matter?`}</h2>
    <p>{`Spacer is an important part of visual design to make functional layouts, deliver clear design and encourage visual rhytm. Provides easy-to-understand, a label telling us what’s the pixel value of the vertical or horizontal space and code variables to apply and maintain space within and between a components.`}</p>
    <h3>{`Spacing units`}</h3>
    <p>{`Depending on the container, we recommend spacing the component using one of the following units (in pixels). In basic units the 4 pixel grid allows components to scale consistently across all display sizes on the website platform.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/spacer/spacing1.svg",
      "alt": "spacing units"
    }}></img>
    <h3>{`Platform spesific`}</h3>
    <p>{`Our spacer is built on a base increment of 16px, with factors of 4px and 8px. You’ll notice that dekstop and mobile spacing units are slightly different. This reflects the design needs of each platform. Use these increments to maintain consistency.`}</p>
    <div className="divi" />
    <h2>{`Spacer Type`}</h2>
    <p>{`Resisting the temptation of introducing spacers into our early design process is very important. To further our understanding and communication of space. We use basic spacers for anywhere we need to apply horizontal or vertical space in our layouts.`}</p>
    <p>{`We reference 2 types of space and apply them in 2 ways:`}</p>
    <ul>
      <li parentName="ul">{`Inline spacing`}</li>
      <li parentName="ul">{`Stack spacing`}</li>
    </ul>
    <h3>{`Inline spacing`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/spacer/spacing2.svg",
      "alt": "inline spacing"
    }}></img>
    <h3>{`Stack spacing`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/spacer/spacing3.svg",
      "alt": "stack spacing"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      